import {
    Checkbox,
    Alert,
    Box,
    Card,
    CardContent,
    Grid,
    ButtonGroup,
    Snackbar,
    IconButton,
    Breadcrumbs,
    StyledBreadcrumb,
    Slider,
    Link,
    Paper,
} from "@mui/material";
import {
    Brightness5Sharp,
    PlayCircleOutline,
    PauseCircleOutline,
    DownloadForOfflineOutlined,
    VolumeUpOutlined,
    IndeterminateCheckBox,
    AddBox,
    Share,
    InsertLink,
    ContentCopy,
    Brightness4,
} from "@mui/icons-material";
import "./Dua.css";
import React, {useState, useRef} from "react";

function Dua(props) {
    const favoriteValueHandler = (el) => {
        props.favoriteValue(el);
    };

    const [isPlaying, setIsPlaying] = useState(false);

    const audioRef = useRef(null);
    const handlePlayPause = () => {
       isPlaying?audioRef.current.pause():audioRef.current.play();
       setIsPlaying(!isPlaying);
    };
    const handleDownload = () => {
        const audioSrc = audioRef.current.currentSrc;

        if (audioSrc) {
            const link = document.createElement('a');
            link.href = audioSrc;
            link.download = 'audio-file.mp3';
            link.click();
        } else {
            console.log('Audio source not found.');
        }
    };





    const [open, setOpen] = useState(false);
    const handleClickCopy = () => {
        setOpen(true);
        navigator.clipboard.writeText(
            "Дуа читаемое " + props.textChapter +
            "\n\n" +
            props.textArabic +
            "\n\n" +
            props.textTranslit +
            "\n\n" +
            props.textTranslation +
            "\n\n" +
            props.reference
        );
    };

    return (
        <Card
            sx={{
                borderRadius: "3px",
                boxShadow: 0,
                m: 3,
                p: 1,
                bgcolor: "#fafafa",
            }}
        >
            <Box>
                {/*<Breadcrumbs separator={"/"} aria-label="breadcrumb">*/}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        border: 0,
                    }}
                >
                    <ButtonGroup
                        aria-label="  outlined button group"
                        sx={{
                            border: 1,
                            bgcolor: "#f0f0f0",
                            borderColor: "white",
                            alignContent: "flex-end",
                        }}
                    >
                        <Checkbox
                            sx={{color: "#1976d2"}}
                            icon={<Brightness5Sharp/>}
                            checkedIcon={<Brightness4/>}
                            checked={
                                props.favoritesList
                                    ? props.favoritesList.includes(props.number)
                                        ? true
                                        : false
                                    : ""
                            }
                            onClick={() => favoriteValueHandler(props.number)}
                        />
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={handleClickCopy}
                        >
                            <ContentCopy/>
                            <Snackbar
                                open={open}
                                onClose={() => setOpen(false)}
                                autoHideDuration={2000}
                                message="Скопировано в буффер!"
                            />
                        </IconButton>
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                        >
                            <Share/>
                        </IconButton>
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => props.setZikr(props.number)}
                        >
                            <InsertLink/>
                        </IconButton>
                    </ButtonGroup>
                </Box>

                <Paper
                    elevation={0}
                    sx={{
                        fontSize: 22,
                        p: 1,
                        bgcolor: "#fafafa",
                    }}
                >
                    {props.textChapter}
                </Paper>
                {props.textArabic ? (
                    <Paper
                        elevation={0}
                        sx={{
                            fontSize: props.fontSize,
                            fontFamily: "Traditional Arabic",
                            color: "green",
                            m: 1,
                            p: 2,
                            bgcolor: "#EDF7ED",
                        }}
                    >

                        {props.textArabic}
                        <br/>


                         <audio id="duaPlayed" ref={audioRef} loop >
                            <source
                                src="https://audio-samples.github.io/samples/mp3/blizzard_unconditional/sample-0.mp3"
                                type="audio/mpeg"
                            />

                        </audio>

                         {isPlaying ? (
                            <PauseCircleOutline
                                fontSize="large"
                                color="primary"
                                onClick={handlePlayPause}
                            />
                        ) : (
                            <PlayCircleOutline
                                fontSize="large"
                                color="primary"
                                onClick={handlePlayPause}
                            />
                        )}

                        <DownloadForOfflineOutlined
                            fontSize="large"
                            color="primary"
                            onClick={handleDownload}
                        />

                        <VolumeUpOutlined
                            fontSize="large"
                            color="primary"
                            onClick={()=> document.getElementById("duaPlayed").volume()}

                        />

                        {" "}
                    </Paper>
                ) : (
                    ""
                )}
                {props.textTranslit ? (
                    <Paper
                        elevation={0}
                        sx={{
                            m: 1,
                            p: 2,
                            bgcolor: "#e3f2fd",
                        }}
                    >
                        {props.textTranslit}
                    </Paper>
                ) : (
                    ""
                )}
                {props.textTranslation ? (
                    <Paper
                        elevation={0}
                        sx={{
                            m: 1,
                            p: 2,
                            bgcolor: "#eeeeee",
                        }}
                    >
                        {props.textTranslation}
                    </Paper>
                ) : (
                    ""
                )}
                {  props.audioLink ? (
                    <audio controls autoPlay>
                        <source src={props.audioLink} type="audio/mpeg"/>
                    </audio>
                ) : (
                    ""
                )}
                {props.reference ? (
                    <Paper
                        variant="outlined"
                        sx={{
                            m: 1,
                            p: 2,
                            fontStyle: "italic",
                            bgcolor: "#fafafa",
                        }}
                    >
                        {props.reference}
                    </Paper>
                ) : (
                    ""
                )}
            </Box>
        </Card>
    );
}

export default Dua;
