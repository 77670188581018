import React, {useState} from "react";

import {
    ToggleButtonGroup,
    ToggleButton,
    IconButton,
    FormControlLabel,
    Slider,
    Switch,
    Button,
    FormGroup,
    Dialog,
    DialogTitle,
} from "@mui/material";
import {Settings as SettingsIcon} from "@mui/icons-material";

const Settings = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [onChange, setOnChange] = useState(true);

    const onChangeTransliteration = () => {
        props.setOnTransliterationHandler(!props.onTransliteration);
    };

    const onChangeTranslation = () => {
        props.setOnTranslationHandler(!props.onTranslation);
    };

    const onChangeReference = () => {
        props.setOnReferenceHandler(!props.onReference);
    };

    const onChangeAudio = () => {
        props.setOnAudioHandler(!props.onAudio);
    };

    const resetSettings = () => {
        props.setOnTransliterationHandler(true);
        props.setOnTranslationHandler(true);
        props.setOnReferenceHandler(false);
        props.setOnAudioHandler(false);
    };

    const output = () =>  {

    };

    return (<>
        <IconButton aria-label="settings" component="span" onClick={handleShow}>
            <SettingsIcon/>
        </IconButton>
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={show}
        >
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {props.settingsTextLanguage.language_show_settings}
            </DialogTitle>

            <ToggleButtonGroup color="primary" value={props.selectedLanguage} exclusive>
                <ToggleButton value="en" onClick={() => props.languageValue("en")}>English</ToggleButton>
                <ToggleButton value="fr" onClick={() => props.languageValue("fr")}>Français</ToggleButton>
                <ToggleButton value="ru" onClick={() => props.languageValue("ru")}>Русский</ToggleButton>
                <ToggleButton value="ce" onClick={() => props.languageValue("ce")}>Нохчийн</ToggleButton>
                <ToggleButton value="ar" onClick={() => props.languageValue("ar")}>العربية</ToggleButton>
            </ToggleButtonGroup>

            <Slider defaultValue={props.fontSize}
                    aria-label="Default"
                    min={20}
                    step={2}
                    max={70}
                    onChange={(event,value)=>props.setFontSize(value)}
                    valueLabelDisplay="auto"/>

            <FormGroup>
                <FormControlLabel
                    sx={props.selectedLanguage==="ar"?"display:none":""}
                    onChange={onChangeTransliteration}
                    checked={props.onTransliteration}
                    control={<Switch/>}
                    label={props.settingsTextLanguage.language_show_translit}
                />
                 <FormControlLabel
                     sx={props.selectedLanguage==="ar"?"display:none":""}
                     onChange={onChangeTranslation}
                    checked={props.onTranslation}
                    control={<Switch/>}
                    label={props.settingsTextLanguage.language_show_translation}
                />
                 <FormControlLabel
                    onChange={onChangeReference}
                    checked={props.onReference}
                    control={<Switch/>}
                    label={props.settingsTextLanguage.language_show_reference}
                />
                <FormControlLabel
                    onChange={onChangeAudio}
                    checked={props.onAudio}
                    control={<Switch/>}
                    label={props.settingsTextLanguage.language_show_audio}
                />
            </FormGroup>
            <Button variant="text" onClick={resetSettings}>
                {props.settingsTextLanguage.language_show_reset}
            </Button>
            <Button variant="contained" onClick={handleClose}>
                {props.settingsTextLanguage.language_show_close}
            </Button>
        </Dialog>
    </>);
};

export default Settings;
